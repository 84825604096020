/* 卡片格式 */
<template>
  <div class="wrapperTable">
    <el-row class="head_info_bar fixbuju head_info">
      <el-col :span="3">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
      </el-col>
      <el-col :span="4">
        <span>商品信息</span>
      </el-col>
      <el-col :span="2">
        商品规格
      </el-col>
      <el-col :span="1" style="text-align:center">
        单位
      </el-col>
      <el-col :span="2">
        <p>平台SPU</p>
        <p>平台SKU</p>
      </el-col>
      <el-col :span="4">
        销售单价
      </el-col>
      <el-col :span="4">
        订购数量
      </el-col>
      <el-col :span="4">
        金额小计
      </el-col>
    </el-row>
    <div v-if="tableList && tableList.length > 0">
      <div class="main_box" v-for="(item, index) in tableList" :key="index">
        <div class="main_head">
          <div>
            <el-checkbox
              v-model="item.is_selected"
              @change="
                val => {
                  checked_btn(val, item);
                }
              "
            ></el-checkbox>
            <h4 style="margin-left:15px" class="ellipsisText " :title="item.seller_company_name">
              {{ item.seller_company_name || "--" }}
            </h4>
            <p style="margin: 0 15px" class="ellipsisText " :title="item.seller_company_code">
              ({{ item.seller_company_code || "--" }})
            </p>
            <i class="el-icon-goods empha" @click="gotoHomePage(item)"></i>
          </div>
          <div>
            <div>
              <div class="status_box statusRed" v-if="item.order_status == 10">待收单</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 20">待付款</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 30">已付款</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 40">待发货</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 50">待收货</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 60">已收货</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 70">已取消</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 80">已关闭</div>
              <div class="status_box statusRed" v-else-if="item.order_status == 90">已删除</div>
            </div>
            <template v-if="item.pay">
              <div class="status_box statusRed" v-if="item.pay && item.pay.status == 1">未支付</div>
              <div class="status_box statusGreen" v-else-if="item.pay && item.pay.status == 2">
                已支付
              </div>
              <div style="margin-right:15px">
                <template v-if="item.pay && item.pay.confirmed_status == 0">
                  <span style="background-color: #ff6200" class="colors"></span>
                  <span style="color: #ff6200">待确认</span>
                </template>
                <template v-else-if="item.pay && item.pay.confirmed_status == 1">
                  <span style="background-color: #7ac756" class="colors"></span>
                  <span style="color: #7ac756">确认通过</span>
                </template>
                <template v-else-if="item.pay && item.pay.confirmed_status == 2">
                  <span style="background-color: #f74848" class="colors"></span>
                  <span style="color: #f74848">确认不通过</span>
                </template>
              </div>
            </template>
            <template v-if="item.order_invoice">
              <div
                class="status_box statusYellow"
                v-if="item.order_invoice && item.order_invoice.apply_status == 0"
              >
                未开票
              </div>
              <div
                class="status_box statusGreen"
                v-else-if="item.order_invoice && item.order_invoice.apply_status == 1"
              >
                已开票
              </div>
              <div
                class="status_box statusGray"
                v-else-if="item.order_invoice && item.order_invoice.apply_status == 2"
              >
                已取消
              </div>
              <div
                v-if="item.order_invoice && item.order_invoice.sign_status == 0"
                style="margin-right:15px"
              >
                <span style="background-color: #f8ba43" class="colors"></span>
                <span style="color: #f8ba43">未签收</span>
              </div>
              <div
                v-else-if="item.order_invoice && item.order_invoice.sign_status == 1"
                style="margin-right:15px"
              >
                <span style="background-color: #7ac756" class="colors"></span>
                <span style="color: #7ac756">已签收</span>
              </div>
              <div
                v-else-if="item.order_invoice && item.order_invoice.sign_status == 2"
                style="margin-right:15px"
              >
                <span style="background-color: #f95c48" class="colors"></span>
                <span style="color: #f95c48">已拒收</span>
              </div>
              <div v-else>
                --
              </div>
            </template>
            <div class="ellipsisText " :title="item.serial_number">
              <span style="color:#92938e">订单编号</span>
              <span style="margin: 0 15px ;" class="empha">
                {{ item.serial_number || "--" }}
              </span>
            </div>
            <span style="color:#92938e" class="ellipsisText " :title="item.created_at">
              {{ item.created_at || "--" }}
            </span>
          </div>
        </div>
        <div class="second_line">
          <div>
            <span>来源:</span>
            <span style="margin: 0 15px ;">
              <template v-if="item.source == 1">
                自主新增
              </template>
              <template v-else-if="item.source == 2">
                企业购物车
              </template>
              <template v-else-if="item.source == 3">
                商城下单
              </template>
              <template v-else>
                --
              </template>
            </span>
          </div>
          <div>
            <h4 style="margin-left:15px">{{ item.buyer_company_name || "--" }}</h4>
            <p style="margin: 0 15px">({{ item.buyer_company_code || "--" }})</p>
          </div>
        </div>
        <div
          :style="`background-color:${(ind + 1) % 2 == 0 ? '#f2f2f2' : '#fff'}`"
          v-for="(it, ind) in item.order_com_sku"
          :key="ind"
        >
          <el-row class="main_info">
            <el-col :span="1" style="text-align:center">{{ ind + 1 }}</el-col>
            <el-col :span="2">
              <div style="padding:15px">
                <el-image
                  style="width: 85%; height: 85%"
                  :src="it.com_sku && it.com_sku.cover_img_path"
                  :preview-src-list="[it.com_sku && it.com_sku.cover_img_path]"
                  fit="contain"
                ></el-image>
              </div>
            </el-col>
            <el-col :span="4">
              <div
                style="margin: 4px 0;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.name"
              >
                {{ (it.com_sku && it.com_sku.name) || "--" }}
              </div>
              <div
                style="margin: 4px 0;"
                class="ellipsisText"
                :title="
                  `${it.com_sku && it.com_sku.serial_number}${
                    it.com_sku && it.com_sku.com_sku_goods
                      ? '-' + it.com_sku.com_sku_goods.goods_small
                      : ''
                  }`
                "
              >
                {{
                  `${it.com_sku && it.com_sku.serial_number}${
                    it.com_sku && it.com_sku.com_sku_goods
                      ? "-" + it.com_sku.com_sku_goods.goods_small
                      : ""
                  }`
                }}
              </div>
              <div
                style="margin: 4px 0;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.info"
              >
                {{ (it.com_sku && it.com_sku.info) || "--" }}
              </div>
            </el-col>
            <el-col :span="2">
              <div>
                <div v-if="it.spec_json">
                  <div v-for="(itt, indd) in it.spec_json" :key="indd" style="margin: 5px 0">
                    <p
                      class="ellipsisText"
                      :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                      v-if="indd < 5"
                    >
                      {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{
                        itt.spec_unit_name
                      }}
                    </p>
                  </div>
                  <el-popover
                    v-if="it.spec_json.length > 4"
                    placement="right"
                    width="180"
                    trigger="hover"
                  >
                    <div style="padding: 10px">
                      <p
                        v-for="(itt, indd) in it.spec_json"
                        :key="indd"
                        :title="
                          itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name
                        "
                      >
                        {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{
                          itt.spec_unit_name
                        }}
                      </p>
                    </div>
                    <span slot="reference">...</span>
                  </el-popover>
                </div>
                <div v-else>
                  --
                </div>
              </div>
            </el-col>
            <el-col :span="1" style="text-align:center">
              <div class="ellipsisText" :title="it.com_sku_unit_name || '--'">
                {{ it.com_sku_unit_name || "--" }}
              </div>
            </el-col>
            <el-col :span="2">
              <div
                style="margin: 4px 0;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.spu_number"
              >
                {{ (it.com_sku && it.com_sku.spu_number) || "--" }}
              </div>
              <div
                style="margin: 4px 0;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.sku_number"
              >
                {{ (it.com_sku && it.com_sku.sku_number) || "--" }}
              </div>
            </el-col>
            <el-col :span="4">
              <p class="ellipsisText" :title="it.com_sku_price">
                {{ it.com_sku_price | formatMoney }}
              </p>
            </el-col>
            <el-col :span="4">
              <div style="margin: 4px 0;" class="ellipsisText" :title="it.buy_count">
                {{ it.buy_count || "--" }}
              </div>
            </el-col>
            <el-col :span="4">
              <p class="ellipsisText" :title="it.com_sku_subtotal">
                {{ it.com_sku_subtotal | formatMoney }}
              </p>
            </el-col>
          </el-row>
        </div>
        <el-row class="price_bar">
          <el-col :span="8" :offset="2">
            商品总额:
            <span style="fontSize:18px;fontWeight:600;margin-left:10px" :title="item.order_com_sku">
              {{ item.order_com_sku | formatMoney }}
            </span>
          </el-col>

          <el-col :span="6">
            折扣:
            <span style="fontSize:18px;fontWeight:600;margin-left:10px">-￥0.00</span>
          </el-col>
          <el-col :span="8">
            订单总额:
            <span
              style="fontSize:18px;fontWeight:600;margin-left:10px;color:#ca151d"
              :title="item.invoice_amount"
            >
              {{ item.order_amount | formatMoney }}
            </span>
          </el-col>
        </el-row>
        <el-row class="price_bar" v-if="[30, 40, 50, 60, 80].includes(item.order_status)">
          <el-col :span="8" :offset="2">
            已付款金额:
            <span style="fontSize:18px;fontWeight:600;margin-left:10px;" :title="item.pay_amount">
              {{ item.pay_amount | formatMoney }}
            </span>
          </el-col>

          <el-col :span="6">
            收货金额:
            <span
              style="fontSize:18px;fontWeight:600;margin-left:10px;"
              :title="item.receipt_amount"
            >
              {{ item.receipt_amount | formatMoney }}
            </span>
          </el-col>
          <el-col :span="8">
            开票金额:
            <span
              style="fontSize:18px;fontWeight:600;margin-left:10px;"
              :title="item.invoice_amount"
            >
              {{ item.invoice_amount | formatMoney }}
            </span>
          </el-col>
        </el-row>
        <div class="main_bot">
          <div>
            <span style="margin-right:45px;color:#858585;fontSize:10px">配送至</span>
            <div v-if="item.shipping">
              <span style="margin-right:15px">
                {{ (item.shipping && item.shipping.receive_name) || "--" }}
              </span>
              <span style="margin-right:15px">
                {{ (item.shipping && item.shipping.receive_mobile) || "--" }}
              </span>
              <span style="margin-right:15px">
                {{ item.shipping && item.shipping.receive_address }}
              </span>
            </div>
            <div v-else>
              --
            </div>
          </div>
          <div class="btn_bar">
            <button
              :class="renderButton(i)"
              style="fontWeight:600;"
              @click="action(item, i)"
              v-for="i in item._oper"
              :key="i.code"
            >
              {{ i.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 取消订单 -->
    <cancelBox ref="cancelBox" />
    <!-- 发货弹窗
    <shipmentDraw ref="shipmentDraw" /> -->
    <!-- 收货弹窗 -->
    <receiptDraw ref="receiptDraw" />
    <!-- 收货弹窗详情 -->
    <receiptDrawDetail ref="receiptDrawDetail" />
  </div>
</template>
<script>
import cancelBox from "./cancelBox.vue";
import shipmentDraw from "./shipmentDraw.vue";
import receiptDraw from "./receiptDraw.vue";
import receiptDrawDetail from "./receiptDrawDetail.vue";
export default {
  name: "",
  components: {
    cancelBox,
    shipmentDraw,
    receiptDraw,
    receiptDrawDetail,
  },
  computed: {},
  inject: ["query"],
  props: {
    tableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checkedData: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleCheckAllChange(val) {
      if (val) {
        this.tableList.forEach(v => {
          v.is_selected = true;
        });
        this.checkedData = JSON.parse(JSON.stringify(this.tableList));
      } else {
        this.tableList.forEach(v => {
          v.is_selected = false;
        });
        this.checkedData = [];
      }
      this.isIndeterminate = false;
    },
    checked_btn(value, item) {
      console.log(value, item);
      this.checkedData = this.tableList.filter(v => v.is_selected);
      let checkedCount = this.checkedData.length;
      this.checkAll = checkedCount === this.tableList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableList.length;
    },
    subtotal(arr) {
      if (arr && arr.length > 0) {
        let total = arr.reduce((sum, product) => sum + Number(product.com_sku_subtotal), 0);
        return total;
      }
    },
    action(row, i) {
      console.log(row, i);
      if (i.name == "详情") {
        //详情
        this.$router.push({
          path: "/myOrder/detail",
          query: {
            id: row.id,
          },
        });
      }
      if (i.name == "收货") {
        //收货
        this.$refs.receiptDraw.open({ id: row.id }, val => {
          console.log(val);
          if (val) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.query();
          }
        });
      }
      if (i.name == "收货详情") {
        //收货详情
        this.$refs.receiptDrawDetail.open({ id: row.id });
      }
      if (i.name == "线下支付") {
        //支付 //支付
        let ids = [row.id];
        let obj = {
          ids,
        };
        sessionStorage.setItem("payingIdsMy", JSON.stringify(obj));
        this.$router.push({
          path: "/myOrder/offlinePay",
        });
      }
      if (i.name == "取消订单") {
        //取消订单
        this.$refs.cancelBox.open({}, val => {
          console.log(val);
          let params = {
            id: row.id, //订单id
            cancel_reason: val, //取消原因
          };
          this.$axios.putJSON("/shop/public/common/personal/cancel", params).then(res => {
            if (res.code == 200) {
              console.log(res);
              this.$message.success("操作成功");
              this.query();
            }
          });
        });
      }
      if (i.name == "复制订单") {
        //复制订单
        this.$confirm(
          `是否确认复制订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/copy`;
            this.$axios.postJSON(api, { id: row.id }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "删除") {
        //删除
        this.$confirm(`是否确认删除订单编号：${row.serial_number}}订单？`, "删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/delete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "恢复") {
        //恢复
        this.$confirm(
          `是否确认恢复订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/restore`;
            this.$axios.postJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "彻底删除") {
        //彻底删除
        this.$confirm(`一经删除后永不可恢复，是否确认删除？`, "彻底删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/complete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "线下开票") {
        //线下开票
        let ids = [row.id];
        let obj = {
          ids,
        };
        sessionStorage.setItem("invoicingIdsMyMy", JSON.stringify(obj));

        this.$router.push({
          path: "/myOrder/offlineInvoice",
        });
      }
      if (i.name == "签收" || i.name == "拒收") {
        //详情
        this.$router.push({
          path: "/myOrder/sign",
          query: {
            id: Number(row.id),
          },
        });
      }
      if (i.name == "线上支付") {
        /* 跳转 */
        let data = {
          token: sessionStorage.getItem("AccessToken"),
          domin: location.origin,
          order_id: row.id,
          order_type: "shop",
        };
        location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(data)}`;
      }
    },
    renderButton(i) {
      let className = "";
      if (["取消订单", "彻底删除", "恢复", "删除"].includes(i.name)) {
        className = "button-81";
      } else if (["删除"].includes(i.name)) {
        className = "button-26";
      } else {
        className = "button-77";
      }
      return className;
    },
    gotoHomePage(item) {
      console.log(item);
      this.link = location.origin + "/_shop/index?cid=" + item.buyer_company_id;
      window.open(this.link);
    },
  },
};
</script>
<style lang="scss" scoped>
.fixbuju {
  position: sticky;
  z-index: 1;
  top: 0px;
}
.wrapperTable {
  margin: 15px 0;
}

/* CSS */
.button-28 {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fb6f23;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fb6f23;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  line-height: 35px;
  width: 100px;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin: 15px;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-image: linear-gradient(to right, #f83600 0%, #fd8c31 100%);
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}
.head_info_bar {
  background-color: #f2f2f2;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.head_info {
  display: flex;
  line-height: 35px;
  align-items: center;
  padding: 0 36px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
}
.main_info {
  display: flex;
  align-items: center;
  margin: 15px 0;
  min-height: 120px;
  box-sizing: border-box;
}
.main_box {
  background-color: #f9f9f9;
  padding: 10px 36px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  .main_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
  }
  .second_line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
    div {
      display: flex;
      align-items: center;
    }
  }
  .main_bot {
    margin-top: 25px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      display: flex;
      align-items: center;
    }
  }
}
.status_box {
  width: 100px;
  line-height: 24px;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 25px;
  margin-right: 15px;
}
.statusRed {
  color: #ca151d;
  border-color: #ca151d;
  background-color: #fae7e8;
}
.statusYellow {
  color: #fac96c;
  border-color: #fac96c;
  background-color: #fef8ec;
}
.statusGreen {
  color: #7ac756;
  border-color: #7ac756;
  background-color: #f1f9ee;
}
.statusGray {
  color: #666;
  border-color: #666;
  background-color: #f2f2f2;
}
.status5 {
  color: rgb(155, 155, 155);
  border-color: rgb(155, 155, 155);
  background-color: rgb(236, 236, 236);
}
.price_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;

  color: #898484;
  span {
    color: #001;
  }
}
.btn_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-left: 50px;
  }
}
/* CSS */
.button-81 {
  width: 90px;
  line-height: 32px;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button-81:hover {
  background-color: #9c9c9c;
  color: #fff;
  transform: translateY(-2px);
}
.button-81:active {
  box-shadow: none;
  transform: translateY(0);
}
.button-77 {
  width: 90px;
  line-height: 32px;
  background-color: #fff;
  border: 1px solid #649cfa;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #5593f9;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button-77:hover {
  background-color: #649cfa;
  color: #fff;
  transform: translateY(-2px);
}
.button-77:active {
  box-shadow: none;
  transform: translateY(0);
}
.shopBtn {
  color: #f8ba43;
  background-color: #fff;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  display: inline-block;
  border-width: 0;
  appearance: none;
}
.shopBtn:hover {
  color: #ff8000;
  font-weight: bold;
  transform: translateY(-2px);
}
.shopBtn:active {
  box-shadow: none;
  transform: translateY(0);
}
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
