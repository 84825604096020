/* 我的订单 */
<template>
  <div id="main_MyOrder" style="width:1200px; margin:0 auto">
    <el-container style="height: 100%">
      <el-header height="auto">
        <div class="head_info_bar">
          <el-row>
            <el-col :span="22" class="nav_bar" style="padding:0">
              <div class="nav">
                <span
                  @click="choseNav(item)"
                  v-for="item in navArr"
                  :key="item.id"
                  :class="[navtype == item.id ? 'typeActive' : '']"
                >
                  {{ item.name }}
                </span>
              </div>
            </el-col>
            <el-col :span="1" :offset="1" align="right" style="line-height: 50px;">
              <!-- <span style="margin-right:20px" class="empha" @click="$router.go(-1)">返回</span> -->
            </el-col>
          </el-row>
        </div>

        <el-row style="padding:15px" class="head_info_bar">
          <fw-nav :array="array" :type="types" @choseNav="choseThis" />
        </el-row>
        <el-row style="padding:15px" class="head_info_bar">
          <fw-serchHeader
            :model="inputs"
            @search="searList"
            ref="searchHeaders"
            @clickEmpty="clickEmpty"
          />
        </el-row>
        <el-row class="head_info_bar" style="padding:15px;display: flex;align-items: center;">
          <el-col :span="3" align="left">
            <div v-for="(item, index) in batchBtns" :key="index" class="icons">
              <span :title="item.name" @click="batchs(item)" :class="renderIcon(item.code, item)">
                {{ item.content }}
              </span>
            </div>
          </el-col>
          <el-col :span="21" align="right" type="flex">
            <div class="right_top">
              <el-checkbox-group
                v-model="order_status"
                @change="changestatus"
                style="margin-right:12px"
              >
                <template v-if="[1].includes(types)">
                  <el-checkbox :label="20">待付款</el-checkbox>
                  <el-checkbox :label="70">已取消</el-checkbox>
                  <el-checkbox :label="40">待发货</el-checkbox>
                  <el-checkbox :label="50">待收货</el-checkbox>
                  <el-checkbox :label="60">已收货</el-checkbox>
                  <el-checkbox :label="80">已关闭</el-checkbox>
                  <el-checkbox :label="90">已删除</el-checkbox>
                </template>
                <template v-else>
                  <el-checkbox :label="10">待收单</el-checkbox>
                  <el-checkbox :label="70">已取消</el-checkbox>
                  <el-checkbox :label="40">待发货</el-checkbox>
                  <el-checkbox :label="50">待收货</el-checkbox>
                  <el-checkbox :label="20">待付款</el-checkbox>
                  <el-checkbox :label="30">已付款</el-checkbox>
                  <el-checkbox :label="80">已关闭</el-checkbox>
                  <el-checkbox :label="90">已删除</el-checkbox>
                </template>
              </el-checkbox-group>
              <span @click="choseType(2)" :class="['iconsd1', listType == 2 ? 'ischose' : '']">
                <i class="el-icon-s-unfold"></i>
              </span>
              <span @click="choseType(1)" :class="['iconsd2', listType == 1 ? 'ischose' : '']">
                <i class="el-icon-menu"></i>
              </span>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-row style="padding:15px" class="head_info_bar">
          <div v-show="listType == 2">
            <tableForm :tableList="tableList" ref="tableForm" />
          </div>
          <div v-show="listType == 1">
            <cardForm :tableList="tableList" ref="cardForm" />
          </div>
        </el-row>
        <div style="backgroundColor:#fff;margin:15px 0">
          <fw-pageNumber align="right" :pages="pages" @changePage="changePage" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import tableForm from "./components/tableForm.vue";
import cardForm from "./components/cardForm.vue";
export default {
  name: "myOrder",
  components: {
    tableForm,
    cardForm,
  },
  computed: {},
  data() {
    return {
      types: 1, //切换卡里初始的位置
      navtype: 2,
      navArr: [
        {
          name: "购物车",
          id: 1,
          url: "/shoppingCar",
        },
        {
          name: "我的订单",
          id: 2,
          url: "/myOrder",
        },
      ],
      array: [
        //切换卡
        {
          name: "先款后货",
          id: 1,
        },
        {
          name: "先货后款",
          id: 2,
        },
      ],
      serachType: 1, //搜索模式
      inputs: [
        {
          type: "input",
          field: "serial_number",
          label: "订单编号",
          value: "",
        },
        {
          type: "input",
          field: "com_sku_name",
          label: "商品名称",
          value: "",
        },
        {
          type: "input",
          field: "com_sku_serial_number",
          label: "商品编号",
          value: "",
        },
        {
          type: "input",
          field: "seller_company_name",
          label: "销售方名称",
          value: "",
        },
        {
          type: "time",
          field: "created_at",
          label: "下单时间",
          value: "",
        },
        {
          type: "star_end",
          field: "order_amount",
          label: "订单总额",
          placeholder: "请输入",
          value: undefined,
          value1: undefined,
          unit: "",
        },
        {
          type: "input",
          field: "com_sku_info",
          label: "商品描述",
          value: "",
        },
        {
          type: "input",
          field: "buyer_company_name",
          label: "所属公司",
          value: "",
        },

        // {
        //   type: "select",
        //   field: "source",
        //   label: "来源",
        //   value: "",
        //   options: [
        //     {
        //       name: "自主新增",
        //       value: 1,
        //     },
        //     {
        //       name: "企业购物车",
        //       value: 2,
        //     },
        //     {
        //       name: "商城下单",
        //       value: 3,
        //     },
        //     {
        //       name: "采购申请下单",
        //       value: 4,
        //     },
        //   ],
        // },
        {
          type: "select",
          field: "pay_status",
          label: "订单货款状态",
          value: "",
          options: [
            {
              name: "待支付",
              value: 0,
            },
            {
              name: "部分支付",
              value: 1,
            },
            {
              name: "全部支付",
              value: 2,
            },
            {
              name: "超额支付",
              value: 3,
            },
          ],
        },
        // {
        //   type: "select",
        //   field: "offline_pay_status",
        //   label: "线下货款状态",
        //   value: "",
        //   options: [
        //     {
        //       name: "待确认",
        //       value: 1,
        //     },
        //     {
        //       name: "通过",
        //       value: 2,
        //     },
        //     {
        //       name: "不通过",
        //       value: 3,
        //     },
        //   ],
        // },
        {
          type: "select",
          field: "invoice_status",
          label: "订单发票状态",
          value: "",
          options: [
            {
              name: "未开票",
              value: 0,
            },
            {
              name: "已开票",
              value: 1,
            },
            {
              name: "已取消",
              value: 2,
            },
          ],
        },
        // {
        //   type: "select",
        //   field: "invoicing_status",
        //   label: "发票开票状态",
        //   value: "",
        //   options: [
        //     {
        //       name: "提交中",
        //       value: 1,
        //     },
        //     {
        //       name: "已申请",
        //       value: 2,
        //     },
        //     {
        //       name: "待签到",
        //       value: 3,
        //     },
        //     {
        //       name: "已拒收",
        //       value: 4,
        //     },
        //     {
        //       name: "已签收",
        //       value: 5,
        //     },
        //     {
        //       name: "已取消",
        //       value: 6,
        //     },
        //     {
        //       name: "删除",
        //       value: 7,
        //     },
        //   ],
        // },
      ],
      pages: {
        //页码
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchData: {}, //搜索内容
      tableList: [], //列表数据
      checked: null,
      order_status: [], //订单状态10待收单20待付款30已付款40待发货50待收货60已收货70已取消80已关闭90已删除
      order_model: [1], //付款方式1先款后货2先货后款
      //批量操作
      batchBtns: [
        {
          name: "线上开票",
          code: "online",
        },
        {
          name: "线下开票",
          code: "offline",
        },
        {
          name: "线上支付",
          code: "onpay",
        },
        {
          name: "线下支付",
          code: "offpay",
        },
      ],
      listType: 2, //列表格式
    };
  },
  created() {
    // this.query();
  },
  provide() {
    //注射给子组件方法
    return {
      query: this.query,
    };
  },
  mounted() {},
  activated() {
    console.log(12331232);
    this.query();
  },
  methods: {
    changestatus() {
      this.pages.pageNum = 1;
      this.query();
    },
    choseNav(val) {
      //切换卡
      this.navtype = val.id;
      this.$router.push(val.url);
    },
    choseThis(val) {
      this.pages.pageSize = 10;
      this.pages.pageNum = 1;
      this.types = val;
      this.order_status = [];
      if (val == 1) {
        this.order_model = [1];
      } else {
        this.order_model = [2];
      }
      this.query();
    },
    searList(val) {
      //搜索
      this.pages.pageNum = 1;
      console.log(val);
      let obj = JSON.parse(JSON.stringify(val));
      for (let key in val) {
        if (["order_amount"].includes(key)) {
          obj[key] = [val[key].start, val[key].end];
        } else {
          obj[key] = val[key];
        }
      }
      this.searchData = JSON.parse(JSON.stringify(obj));
      this.serachType = 1;
      this.query();
    },
    query() {
      let params = {
        ...this.searchData,
        order_model: this.order_model,
        order_status: this.order_status,
        page: this.pages.pageNum,
        page_size: this.pages.pageSize,
      };
      console.log(params);
      if (params.order_status.length == 0) {
        delete params.order_status;
      }
      this.$axios.postJSON("/shop/public/common/personal/list", params).then(res => {
        // console.log('res dataf',res.data);
        if (res.code == 200) {
          let data = res.data.data.map(v => {
            v["is_selected"] = false;
            return v;
          });
          if (data) {
            this.tableList = data;
            this.pages.total = res.data.total;
          }
        }
      });
    },
    changePage(val) {
      if (val.type == "size") {
        this.pages.pageSize = val.num;
      } else {
        this.pages.pageNum = val.num;
      }
      this.query();
    },
    batchs(item) {
      console.log(item);
      console.log(item);
      let records = null;
      if (this.listType == 2) {
        records = this.$refs.tableForm.$refs.xTable1.getCheckboxRecords();
      } else {
        records = this.$refs.cardForm.checkedData;
      }
      console.log(records);
      if (records.length == 0) {
        return this.$message.warning("请先勾选数据!");
      }
      let ids = records.map(v => v.id);
      let flag1 = records.every(v => v.seller_company_name == records[0].seller_company_name);
      let flag2 = records.every(v => v.buyer_company_name == records[0].buyer_company_name);
      if (flag1 && flag2) {
        if (item.code == "offline") {
          if (this.types == 1) {
            let flag = records.every(v => [50, 60].includes(v.order_status));
            console.log(flag);
            if (!flag) return this.$message.error("只有 待收货 已收货才能批量线上、线下开票");
          }
          if (this.types == 2) {
            let flag = records.every(v => [20, 30].includes(v.order_status));
            console.log(flag);
            if (!flag) return this.$message.error("只有 待付款 已付款才能批量线上、线下开票");
          }
          let obj = {
            ids,
          };
          sessionStorage.setItem("invoicingIdsMyMy", JSON.stringify(obj));

          this.$router.push({
            path: "/myOrder/offlineInvoice",
          });
        }
        if (item.code == "offpay") {
          if (this.types == 1) {
            let flag = records.every(v => [20, 40, 50, 60].includes(v.order_status));
            console.log(flag);
            if (!flag)
              return this.$message.error("只有待付款 待发货 待收货 已收货才能批量线上、线下支付");
          }
          if (this.types == 2) {
            let flag = records.every(v => [20].includes(v.order_status));
            console.log(flag);
            if (!flag) return this.$message.error("只有 待付款 才能批量线上、线下支付");
          }
          let obj = {
            ids,
          };
          sessionStorage.setItem("payingIdsMy", JSON.stringify(obj));
          this.$router.push({
            path: "/myOrder/offlinePay",
          });
        }
        if (item.code == "onpay") {
          if (this.types == 1) {
            let flag = records.every(v => [20, 40, 50, 60].includes(v.order_status));
            console.log(flag);
            if (!flag)
              return this.$message.error("只有待付款 待发货 待收货 已收货才能批量线上、线下支付");
          }
          if (this.types == 2) {
            let flag = records.every(v => [20].includes(v.order_status));
            console.log(flag);
            if (!flag) return this.$message.error("只有 待付款 才能批量线上、线下支付");
          }
          /* 跳转 */
          let data = {
            token: sessionStorage.getItem("AccessToken"),
            domin: location.origin,
            order_id: ids,
            order_type: "shop",
          };
          location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(data)}`;
        }
      } else {
        return this.$message.warning("所勾选数据中采购方名称和销售方名称必须一致!");
      }
    },
    //获取icons
    renderIcon(code, item) {
      let icon = null;
      if (code == "online") {
        //批量删除
        icon = `fa fa-cloud`;
      } else if (code == "offline") {
        //批量指定负责人
        icon = `fa fa-barcode`;
      } else if (code == "onpay") {
        //批量指定负责人
        icon = `el-icon-sell`;
      } else if (code == "offpay") {
        //批量指定负责人
        icon = `el-icon-coin`;
      }
      return icon;
    },
    choseType(val) {
      this.pages.pageSize = 10;
      this.pages.pageNum = 1;
      this.listType = val;
      this.query();
    },
    clickEmpty() {
      this.order_status = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.fixbuju {
  position: sticky;
  z-index: 1;
  top: 0px;
}
#main_MyOrder {
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
::v-deep .title {
  padding: 15px 15px 10px 15px;
}
.headline {
  background-color: #fff;
  position: sticky;
}
.nav_bar {
  padding: 15px 15px 10px 15px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nav {
    display: flex;
    align-items: center;
  }
  span {
    display: block;
    height: 38px;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    margin-left: 40px;
    font-weight: bolder;
  }
  .typeActive {
    color: $themeColor;
    border-bottom: 2px solid $themeColor;
  }
}
.search_icon {
  font-size: 22px;
  color: #ca151d;

  &:hover {
    font-weight: bolder;
  }
}

.head_info_bar {
  background-color: #fff;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.icons {
  cursor: pointer;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  background-color: #4387f8;
  display: inline-block;
  margin: 10px 5px;
  text-align: center;

  i,
  span {
    color: #fff;
    line-height: 20px;
    font-size: 14px;
    // font-weight: bold;
  }
}
.right_top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.iconsd1 {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  i {
    color: #ccc;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    width: 25px;
  }
  .radius2 {
    border-radius: 0px 5px 5px 0px;
  }
}
.iconsd2 {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 0px 5px 5px 0px;
  i {
    color: #ccc;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    width: 25px;
  }
}
.ischose {
  background-color: rgba(236, 242, 254, 1);
  i {
    color: #0088fe;
  }
}
</style>
