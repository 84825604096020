<template>
  <div class="wrapperTable">
    <vxe-table
      class="mytable-scrollbar"
      style="height:100%"
      stripe
      highlight-hover-row
      size="small"
      id="toolbar_demo3"
      :row-config="{ height: 120 }"
      ref="xTable1"
      :data="tableList"
      :custom-config="{
        storage: true,
      }"
      border
      auto-resize
    >
      <vxe-column
        fixed="left"
        align="center"
        type="checkbox"
        width="50"
        field="checkbox"
      ></vxe-column>
      <vxe-column
        fixed="left"
        type="seq"
        title="序号"
        width="50"
        align="center"
        field="xuhao"
      ></vxe-column>
      <vxe-colgroup title="订单信息" align="center">
        <vxe-column min-width="160">
          <template #header>
            <p>订单编号</p>
            <p>订单业务状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.serial_number">
              {{ row.serial_number || "--" }}
            </p>
            <el-tag type="warning" v-if="row.order_status == 10">待收单</el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 20">
              待付款
            </el-tag>
            <el-tag type="success" v-else-if="row.order_status == 30">
              已付款
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 40">
              待发货
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 50">
              待收货
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 60">
              已收货
            </el-tag>
            <el-tag type="info" v-else-if="row.order_status == 70">
              已取消
            </el-tag>
            <el-tag type="danger" v-else-if="row.order_status == 80">
              已关闭
            </el-tag>
            <el-tag type="danger" v-else-if="row.order_status == 90">
              已删除
            </el-tag>

            <p v-else>
              --
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>订单总额</p>
            <p>订单开票总额</p>
            <p>订单付款金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.order_amount">
              {{ row.order_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.invoice_amount">
              {{ row.invoice_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.pay_amount">
              {{ row.pay_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>订单货款状态</p>
            <p>订单发票状态</p>
          </template>
          <template #default="{ row }">
            <div>
              <template v-if="row.pay_status == 0">
                <span style="background-color: #e6a23c" class="colors"></span>
                <span style="color: #e6a23c">待支付</span>
              </template>
              <template v-else-if="row.pay_status == 1">
                <span style="background-color:#409eff" class="colors"></span>
                <span style="color: #409eff">部分支付</span>
              </template>
              <template v-else-if="row.pay_status == 2">
                <span style="background-color: #7ac756" class="colors"></span>
                <span style="color: #7ac756">全部支付</span>
              </template>
              <template v-else-if="row.pay_status == 3">
                <span style="background-color: #7ac756" class="colors"></span>
                <span style="color: #7ac756">超额支付</span>
              </template>
              <template v-else>
                --
              </template>
            </div>
            <div>
              <template v-if="row.invoice_status == 0">
                <span style="background-color: #f74848" class="colors"></span>
                <span style="color: #f74848">未开票</span>
              </template>
              <template v-else-if="row.invoice_status == 1">
                <span style="background-color: #7ac756" class="colors"></span>
                <span style="color: #7ac756">已开票</span>
              </template>
              <template v-else>
                --
              </template>
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>订单发货金额</p>
            <p>订单收货金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.send_amount">
              {{ row.send_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.receipt_amount">
              {{ row.receipt_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>已收货未开票</p>
            <p>已收货未付款</p>
            <p>已付款未发货</p>
            <p>已付款未开票</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.deliveryed_not_invoice">
              {{ row.deliveryed_not_invoice | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.deliveryed_not_pay">
              {{ row.deliveryed_not_pay | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.payed_not_send">
              {{ row.payed_not_send | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.payed_not_invoice">
              {{ row.payed_not_invoice | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>采购方名称</p>
            <p>统一社会信用代码</p>
            <p>销售方名称</p>
            <p>统一社会信用代码</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="row.buyer_company_name || '--'">
              {{ row.buyer_company_name || "--" }}
            </p>
            <p class="ellipsisText " :title="row.buyer_company_code || '--'">
              {{ row.buyer_company_code || "--" }}
            </p>
            <p class="ellipsisText " :title="row.seller_company_name || '--'">
              {{ row.seller_company_name || "--" }}
            </p>
            <p class="ellipsisText " :title="row.seller_company_code || '--'">
              {{ row.seller_company_code || "--" }}
            </p>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup title="货款信息" align="center">
        <vxe-column min-width="140">
          <template #header>
            <p>付款单号</p>
            <p>支付状态</p>
            <p>线下货款状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.pay && row.pay.serial_number) || '--'">
              {{ (row.pay && row.pay.serial_number) || "--" }}
            </p>
            <div>
              <template v-if="row.pay && row.pay.status == 1">
                <span style="background-color: #f74848" class="colors"></span>
                <span style="color: #f74848">未支付</span>
              </template>
              <template v-else-if="row.pay && row.pay.status == 2">
                <span style="background-color: #7ac756" class="colors"></span>
                <span style="color: #7ac756">已支付</span>
              </template>
              <template v-else>
                --
              </template>
            </div>
            <template v-if="row.pay && row.pay.confirmed_status == 0">
              <span style="background-color: #ff6200" class="colors"></span>
              <span style="color: #ff6200">待确认</span>
            </template>
            <template v-else-if="row.pay && row.pay.confirmed_status == 1">
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">确认通过</span>
            </template>
            <template v-else-if="row.pay && row.pay.confirmed_status == 2">
              <span style="background-color: #f74848" class="colors"></span>
              <span style="color: #f74848">确认不通过</span>
            </template>
            <template v-else>
              --
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>流水单号</p>
            <p>交易时间</p>
            <p>付款金额</p>
            <p>支付方式</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.pay && row.pay.flow_serial_number) || '--'">
              {{ (row.pay && row.pay.flow_serial_number) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.transaction_date) || '--'">
              {{ (row.pay && row.pay.transaction_date) || "--" }}
            </p>
            <h3
              v-if="row.pay && row.pay.pay_amount"
              class="ellipsisText"
              style="color:#f75f8f"
              :title="row.pay && row.pay.pay_amount"
            >
              {{ row.pay && row.pay.pay_amount | formatMoney }}
            </h3>
            <p v-else>--</p>
            <p class="ellipsisText " :title="(row.pay && row.pay.settlement_method) || '--'">
              {{ (row.pay && row.pay.settlement_method) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>付款方名称</p>
            <p>付款方编号</p>
            <p>付款开户行</p>
            <p>付款账号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.pay && row.pay.payer_name) || '--'">
              {{ (row.pay && row.pay.payer_name) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.pay_company_code) || '--'">
              {{ (row.pay && row.pay.pay_company_code) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.pay_bank) || '--'">
              {{ (row.pay && row.pay.pay_bank) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.pay_number) || '--'">
              {{ (row.pay && row.pay.pay_number) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>收款方名称</p>
            <p>收款方编号</p>
            <p>收款开户行</p>
            <p>收款账号</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.pay && row.pay.payee_name) || '--'">
              {{ (row.pay && row.pay.payee_name) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.payee_company_code) || '--'">
              {{ (row.pay && row.pay.payee_company_code) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.payee_bank) || '--'">
              {{ (row.pay && row.pay.payee_bank) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.pay && row.pay.payee_number) || '--'">
              {{ (row.pay && row.pay.payee_number) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="210">
          <template #header>
            <p>经办人 | 经办时间</p>
            <p>确认人 | 确认时间</p>
            <p>确认结果</p>
            <p>备注</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="
                `${(row.pay && row.pay.handled_by) || '--'} | ${(row.pay && row.pay.handled_at) ||
                  '--'}`
              "
            >
              {{
                `${(row.pay && row.pay.handled_by) || "--"} | ${(row.pay && row.pay.handled_at) ||
                  "--"}`
              }}
            </p>
            <p
              class="ellipsisText "
              :title="
                `${(row.pay && row.pay.confirmed_by) || '--'} | ${(row.pay &&
                  row.pay.confirmed_at) ||
                  '--'}`
              "
            >
              {{
                `${(row.pay && row.pay.confirmed_by) || "--"} | ${(row.pay &&
                  row.pay.confirmed_at) ||
                  "--"}`
              }}
            </p>
            <p v-if="row.pay && row.pay.confirmed_status == 2" style="color:#f74848">
              不通过
            </p>
            <p v-else-if="row.pay && row.pay.confirmed_status == 1" style="color:#7ac756">
              通过
            </p>
            <p v-else>--</p>
            <p class="ellipsisText " :title="(row.pay && row.pay.confirmed_desc) || '--'">
              {{ (row.pay && row.pay.confirmed_desc) || "--" }}
            </p>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup title="发票信息" align="center">
        <vxe-column min-width="140">
          <template #header>
            <p>发票申请单号</p>
            <p>申请状态</p>
            <p>开票方式</p>
            <p>发票开票状态</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.apply_serial_number) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.apply_serial_number) || "--" }}
            </p>
            <template v-if="row.order_invoice && row.order_invoice.apply_status == 0">
              <span style="background-color: #f74848" class="colors"></span>
              <span style="color: #f74848">未开票</span>
            </template>
            <template v-else-if="row.order_invoice && row.order_invoice.apply_status == 1">
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">已开票</span>
            </template>
            <template v-else-if="row.order_invoice && row.order_invoice.apply_status == 2">
              <span style="background-color: #ccc" class="colors"></span>
              <span style="color: #ccc">已取消</span>
            </template>
            <template v-else>
              --
            </template>
            <p v-if="row.order_invoice && row.order_invoice.invoice_way == 1">
              线上
            </p>
            <p v-else-if="row.order_invoice && row.order_invoice.invoice_way == 2">
              线下
            </p>
            <p v-else>--</p>
            <div
              v-if="row.order_invoice && row.order_invoice.sign_status == 0"
              style="margin-right:15px"
            >
              <span style="background-color: #f8ba43" class="colors"></span>
              <span style="color: #f8ba43">未签收</span>
            </div>
            <div
              v-else-if="row.order_invoice && row.order_invoice.sign_status == 1"
              style="margin-right:15px"
            >
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">已签收</span>
            </div>
            <div
              v-else-if="row.order_invoice && row.order_invoice.sign_status == 2"
              style="margin-right:15px"
            >
              <span style="background-color: #f95c48" class="colors"></span>
              <span style="color: #f95c48">已拒收</span>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>发票号码</p>
            <p>发票代码</p>
            <p>开票日期</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.invoice_num) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.invoice_num) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.invoice_code) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.invoice_code) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.invoice_date) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.invoice_date) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>发票金额</p>
            <p>税率</p>
          </template>
          <template #default="{ row }">
            <h3
              v-if="row.order_invoice && row.order_invoice.invoice_amount"
              class="ellipsisText"
              style="color:#f75f8f"
              :title="row.order_invoice && row.order_invoice.invoice_amount"
            >
              {{ (row.order_invoice && row.order_invoice.invoice_amount) | formatMoney }}
            </h3>
            <p v-else>--</p>
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.tax_rate) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.tax_rate) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>签收人 | 签收时间</p>
            <p>拒收人 | 拒收时间</p>
            <p>签收意见</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="
                `${(row.order_invoice && row.order_invoice.signed_by) ||
                  '--'} | ${(row.order_invoice && row.order_invoice.signed_at) || '--'}`
              "
            >
              {{
                `${(row.order_invoice && row.order_invoice.signed_by) ||
                  "--"} | ${(row.order_invoice && row.order_invoice.signed_at) || "--"}`
              }}
            </p>
            <p
              class="ellipsisText "
              :title="
                `${(row.order_invoice && row.order_invoice.rejected_by) ||
                  '--'} | ${(row.order_invoice && row.order_invoice.rejected_at) || '--'}`
              "
            >
              {{
                `${(row.order_invoice && row.order_invoice.rejected_by) ||
                  "--"} | ${(row.order_invoice && row.order_invoice.rejected_at) || "--"}`
              }}
            </p>
            <p
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.signed_opinion) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.signed_opinion) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>收票人信息</p>
          </template>
          <template #default="{ row }">
            <template v-if="row.order_invoice && row.order_invoice.invoice_info">
              <p
                class="ellipsisText "
                :title="(row.order_invoice && row.order_invoice.invoice_info.name) || '--'"
              >
                {{ (row.order_invoice && row.order_invoice.invoice_info.name) || "--" }}
              </p>
              <p
                class="ellipsisText "
                :title="renderAddress(row.order_invoice && row.order_invoice.invoice_info.address)"
              >
                {{ renderAddress(row.order_invoice && row.order_invoice.invoice_info.address) }}
              </p>
              <p
                class="ellipsisText "
                :title="(row.order_invoice && row.order_invoice.invoice_info.email) || '--'"
              >
                {{ (row.order_invoice && row.order_invoice.invoice_info.email) || "--" }}
              </p>
            </template>
            <template v-else>
              --
            </template>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>发票类型</p>
            <p>发票种类</p>
            <p>验真状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.order_invoice && row.order_invoice.type) || '--'">
              {{ (row.order_invoice && row.order_invoice.type) || "--" }}
            </p>
            <div
              class="ellipsisText "
              :title="(row.order_invoice && row.order_invoice.invoice_class) || '--'"
            >
              {{ (row.order_invoice && row.order_invoice.invoice_class) || "--" }}
            </div>
            <div v-if="row.order_invoice && row.order_invoice.status_v == 0">
              <el-tag type="warning">未验真</el-tag>
            </div>
            <div v-else-if="row.order_invoice && row.order_invoice.status_v == 1">
              <el-tag type="success">验真通过</el-tag>
            </div>
            <div v-else-if="row.order_invoice && row.order_invoice.status_v == 2">
              <el-tag type="danger">验真失败</el-tag>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup title="货物信息" align="center">
        <vxe-column min-width="140">
          <template #header>
            <p>发运单号</p>
            <p>发运类型</p>
            <p>发运状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.shipping && row.shipping.serial_number) || '--'">
              {{ (row.shipping && row.shipping.serial_number) || "--" }}
            </p>
            <div v-if="row.shipping && row.shipping.shipping_type == 1">
              虚拟
            </div>
            <div v-else-if="row.shipping && row.shipping.shipping_type == 2">
              实物
            </div>
            <div v-else>
              --
            </div>
            <div v-if="row.shipping && row.shipping.shipping_status == 1">
              <span style="background-color: #ff6200" class="colors"></span>
              <span style="color: #ff6200">待承运</span>
            </div>
            <div v-else-if="row.shipping && row.shipping.shipping_status == 2">
              <span style="background-color: #7ac756" class="colors"></span>
              <span style="color: #7ac756">已发运</span>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>物流单号</p>
            <p>快递公司</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsisText "
              :title="(row.shipping && row.shipping.logistics_serial_number) || '--'"
            >
              {{ (row.shipping && row.shipping.logistics_serial_number) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="(row.shipping && row.shipping.logistics_company) || '--'"
            >
              {{ (row.shipping && row.shipping.logistics_company) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>发货方名称</p>
            <p>发货地址</p>
            <p>发货人 | 发货时间</p>
            <p>联系电话</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.shipping && row.shipping.send_name) || '--'">
              {{ (row.shipping && row.shipping.send_name) || "--" }}
            </p>
            <p class="ellipsisText " :title="(row.shipping && row.shipping.send_address) || '--'">
              {{ (row.shipping && row.shipping.send_address) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="
                `${(row.shipping && row.shipping.send_by) || '--'} | ${(row.shipping &&
                  row.shipping.send_at) ||
                  '--'}`
              "
            >
              {{
                `${(row.shipping && row.shipping.send_by) || "--"} | ${(row.shipping &&
                  row.shipping.send_at) ||
                  "--"}`
              }}
            </p>
            <p class="ellipsisText " :title="(row.shipping && row.shipping.send_mobile) || '--'">
              {{ (row.shipping && row.shipping.send_mobile) || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>收货方名称</p>
            <p>收货地址</p>
            <p>收货人 | 收货时间</p>
            <p>联系电话</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="(row.shipping && row.shipping.receive_name) || '--'">
              {{ (row.shipping && row.shipping.receive_name) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="(row.shipping && row.shipping.receive_address) || '--'"
            >
              {{ (row.shipping && row.shipping.receive_address) || "--" }}
            </p>
            <p
              class="ellipsisText "
              :title="
                `${(row.shipping && row.shipping.receive_by) || '--'} | ${(row.shipping &&
                  row.shipping.receive_at) ||
                  '--'}`
              "
            >
              {{
                `${(row.shipping && row.shipping.receive_by) || "--"} | ${(row.shipping &&
                  row.shipping.receive_at) ||
                  "--"}`
              }}
            </p>
            <p class="ellipsisText " :title="(row.shipping && row.shipping.receive_mobile) || '--'">
              {{ (row.shipping && row.shipping.receive_mobile) || "--" }}
            </p>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-column width="180" fixed="right">
        <template #header>
          <p>操作</p>
        </template>
        <template #default="{ row }">
          <template v-if="row._oper && row._oper.length > 0">
            <el-link
              @click="action(row, i)"
              class="links"
              :underline="false"
              type="primary"
              v-for="i in row._oper"
              :key="i.code"
            >
              {{ i.name }}
            </el-link>
          </template>
          <template v-else>
            <el-link class="links">/</el-link>
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 取消订单 -->
    <cancelBox ref="cancelBox" />
    <!-- 发货弹窗
    <shipmentDraw ref="shipmentDraw" /> -->
    <!-- 收货弹窗 -->
    <receiptDraw ref="receiptDraw" />
    <!-- 收货弹窗详情 -->
    <receiptDrawDetail ref="receiptDrawDetail" />
  </div>
</template>

<script>
import cancelBox from "./cancelBox.vue";
import shipmentDraw from "./shipmentDraw.vue";
import receiptDraw from "./receiptDraw.vue";
import receiptDrawDetail from "./receiptDrawDetail.vue";
export default {
  name: "",
  components: {
    cancelBox,
    shipmentDraw,
    receiptDraw,
    receiptDrawDetail,
  },
  props: {
    tableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  inject: ["query"],
  created() {},
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    renderAddress() {
      return obj => {
        if (obj) {
          let msg = obj.province + obj.city + obj.area + obj.address;
          return msg;
        } else {
          return "--";
        }
      };
    },
  },
  methods: {
    action(row, i) {
      console.log(row, i);

      if (i.name == "详情") {
        //详情
        this.$router.push({
          path: "/myOrder/detail",
          query: {
            id: row.id,
          },
        });
      }
      if (i.name == "收货") {
        //收货
        this.$refs.receiptDraw.open({ id: row.id }, val => {
          console.log(val);
          if (val) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.query();
          }
        });
      }
      if (i.name == "收货详情") {
        //收货详情
        this.$refs.receiptDrawDetail.open({ id: row.id });
      }
      if (i.name == "线下支付") {
        //支付
        let ids = [row.id];
        let obj = {
          ids,
        };
        sessionStorage.setItem("payingIdsMy", JSON.stringify(obj));
        this.$router.push({
          path: "/myOrder/offlinePay",
        });
      }

      if (i.name == "取消订单") {
        //取消订单
        this.$refs.cancelBox.open({}, val => {
          console.log(val);
          let params = {
            id: row.id, //订单id
            cancel_reason: val, //取消原因
          };
          this.$axios.putJSON("/shop/public/common/personal/cancel", params).then(res => {
            if (res.code == 200) {
              console.log(res);
              this.$message.success("操作成功");
              this.query();
            }
          });
        });
      }
      if (i.name == "复制订单") {
        //复制订单
        this.$confirm(
          `是否确认复制订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/copy`;
            this.$axios.postJSON(api, { id: row.id }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "删除") {
        //删除
        this.$confirm(`是否确认删除订单编号：${row.serial_number}}订单？`, "删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/delete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "恢复") {
        //恢复
        this.$confirm(
          `是否确认恢复订单编号：<span style="color: #5589f8">${row.serial_number} </span>订单？`,
          "恢复提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true, // 必须添加此选项，以允许在确认框中使用HTML字符串
          }
        )
          .then(() => {
            let api = `/shop/public/common/personal/restore`;
            this.$axios.postJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "彻底删除") {
        //彻底删除
        this.$confirm(`一经删除后永不可恢复，是否确认删除？`, "彻底删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let api = `/shop/public/common/personal/complete`;
            this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.query();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (i.name == "线下开票") {
        //线下开票
        let ids = [row.id];
        let obj = {
          ids,
        };
        sessionStorage.setItem("invoicingIdsMyMy", JSON.stringify(obj));

        this.$router.push({
          path: "/myOrder/offlineInvoice",
        });
      }
      if (i.name == "签收" || i.name == "拒收") {
        //详情
        this.$router.push({
          path: "/myOrder/sign",
          query: {
            id: Number(row.id),
          },
        });
      }
      if (i.name == "线上支付") {
        /* 跳转 */
        let data = {
          token: sessionStorage.getItem("AccessToken"),
          domin: location.origin,
          order_id: row.id,
          order_type: "shop",
        };
        location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(data)}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapperTable {
  margin: 15px 0;
  min-height: 65vh;
  height: 100%;
}
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
.links {
  margin-right: 5px;
}
</style>
